import React, { useRef, useState, useEffect } from 'react'
import { Pagination, Spin, Table, Badge, Input, Button, Tree, message, Card } from 'antd';
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'
import { GetRiskOptions, ImageLineCompressData, GetPointRangeFun, IgnoreAIAxis, SubCheckImg, DelAIAxis, newGetRiskOptionsFun } from "@/services/abnormal_troubleshooting.js";
import local from '@/utils/local'
import hourglass from "@/common/img/abnormal_troubleshooting/hourglass.svg";
import { basicPath } from '@/router/config.js'
import { transformGCJ2WGS } from "@/common/js/common.js" //引入公共方法

function AbnormalTroubleshootingSteps() {
  //路由
  const navigate = useNavigate();
  //传递的数据
  const location = useLocation();
  const [dataList, setDataList] = useState(location.state.arr);//数据
  const dataListRef = useRef(location.state.arr);//数据

  const token = local.get('cruise_m30_token');
  const companyId = local.get('cruise_m30_companyId');

  const [cardHeight, setCardHeight] = useState(0); //卡片高度

  const [currIdx, setCurrIdx] = useState(0);//当前 dataList 数据下标
  const currIdxRef = useRef(0);//当前 dataList 数据下标
  const [bgImageUrl, setBgImageUrl] = useState('');//背景图片地址
  const [imgWidth, setImgWidth] = useState(2000);//图片宽度
  const [imgHeight, setImgHeight] = useState(1500);//图片高度
  const [imgLoading, setImgLoading] = useState(false);//图片是否加载完
  const [loadingText, setLoadingText] = useState('加载中...');//加载提示
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);//
  const [zoom, setZoom] = useState(1); //缩放比例
  const zoomRef = useRef(1);//缩放比例
  const [imgTop, setImgTop] = useState(0);//距顶
  const imgTopRef = useRef(0);
  const [imgLeft, setImgLeft] = useState(0);//距左
  const imgLeftRef = useRef(0);

  //处理图片移动
  const firstXYRef = useRef([0, 0]);//首次点击的位置
  const isClickRef = useRef(false);//是否点击

  //异常类型树
  const [abnormalType, setAbnormalType] = useState([]);//异常框选列表
  const abnormalTypeRef = useRef([]);//异常框选列表
  const parentIdsRef = useRef([]);//异常类型 父级id
  const parentNamesRef = useRef([]);//异常类型 父级名称
  const childIdsRef = useRef([]);//异常类型 子级id
  const [treeData, setTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);//选中的异常类型
  const checkedKeysRef = useRef([]);
  const checkedInfoRef = useRef('');//选中的信息
  const [expandedKeys, setExpandedKeys] = useState([]);//展开的父节点

  //异常框选
  const multiple = 1;//倍数
  const [abnormalList, setAbnormalList] = useState([]);
  const rectangleBorder = 2;//矩形边框粗细
  const abnormalIsClickRef = useRef(false);//是否点击
  const [drawInfo, setDrawInfo] = useState({ //正在绘制的异常
    id: '',//ai识别的带有id
    distance: '',//距离
    x: '',//x轴
    y: '',//y轴
    width: '',//宽度
    height: '',//高度
    color: 'red',//边框颜色
    checked: false,//是否选中
    airisktype: '',//ai识别风险
    risktype: '',//风险父级
    title: '',//风险名称
    isAi: false,//是否为ai识别
    pointerEvents: 'none',//是否穿透
    threshold: '',//阈值
  });
  const [isDraw, setIsDraw] = useState(false);//是否绘制
  const time = useRef(null);//开始点击的时间，与结束时间做比较，判断是点击还是拖动

  const [is_exist, setExist] = useState(false);//是否 触发渲染渲染图片
  const [isInitialize, setIsInitialize] = useState(true);//是否初始化
  const waypointList = useRef([]);//航点范围

  //当前信息
  const [abnormalInfo, setAbnormalInfo] = useState({
    insPointId: '',
    pipelineId: '',
    pipelinepointId: ''
  });
  const [deleteIds, setDeleteIds] = useState([]);//需要删除的id

  //按钮
  const nextBtnRef = useRef(null);//下一张
  const lastBtnRef = useRef(null);//上一张
  const deleteBtnRef = useRef(null);//删除


  //新 获取异常选项
  const getRiskOptions = async (type) => {
    let params = {
      access_token: token,
      type: type
    }
    if (Number(type) === 8 || Number(type) === 9) { //定点
      params.RID = dataList[currIdx].RID;
      params.order = dataList[currIdx].order;
    }
    let res = await newGetRiskOptionsFun(params);
    let expanded = []; //父级 展开
    let items = [];//树
    let ids = [];//父级 值
    let names = [];//父级 名称
    let childs = [];//子级 值
    for (let i = 0; i < res.data.length; i++) {
      let val = res.data[i];
      ids.push(Number(val.risk_id));
      names.push(val.risk_name);
      childs.push(Number(val.risk_cid));
      let idx = expanded.indexOf('r-' + val.risk_id);
      if (idx < 0) { //不存在
        idx = expanded.length;
        expanded.push('r-' + val.risk_id);
        items.push({
          title: val.risk_name,
          key: 'r-' + val.risk_id,
          checkable: false,
          children: [
            { title: val.name, key: Number(val.risk_cid), parentKey: Number(val.risk_id) }
          ],
        })
      } else {//存在
        items[idx].children.push({ title: val.name, key: Number(val.risk_cid), parentKey: Number(val.risk_id) });
      }
    }
    setTreeData(items);
    setAbnormalType(res.data);
    abnormalTypeRef.current = res.data;
    setExpandedKeys(expanded);

    parentIdsRef.current = ids;
    parentNamesRef.current = names;
    childIdsRef.current = childs;

  }

  //获取航点范围
  const getPointRange = async (w, h) => {
    let info = dataList[currIdx];
    let param = {
      access_token: token,
      pointId: info.pointId,
      width: w,
      height: h
    }
    let res = await GetPointRangeFun(param);
    if (res.ret !== 200) {
      message.warning(res.msg || '')
    }
    if (res.data && res.data.length > 0) {
      waypointList.current = res.data;
    } else {
      waypointList.current = [];
      message.warning('没有航点范围');
    }
  }

  //初始化数据
  const initialize = async () => {
    setIsInitialize(false);
    let info = dataList[currIdx];
    let params = {
      access_token: token,
      companyId: companyId,
      insPointId: info.pointId
    };
    setLoading(true);
    loadingRef.current = true;
    //获取类型
    await getRiskOptions(info.routes_type);

    let res = await ImageLineCompressData(params);
    if (res.ret !== 200) {
      setLoading(false);
      loadingRef.current = false;
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let data = res.data;
    let img = new Image();
    img.src = data.picUrlOss;
    img.onload = async () => {
      let w = img.width;
      let h = img.height;
      setImgWidth(w);
      setImgHeight(h);
      if (Number(dataList[currIdx].routes_type) !== 8 && Number(dataList[currIdx].routes_type) !== 9) {
        await getPointRange(w, h);
      }
      setTimeout(() => {
        let dom = document.getElementById('mainDiv');
        if (dom?.clientHeight && dom?.clientWidth) {
          let d = 0;
          if (w > h) { //宽大于高
            d = Math.ceil(Number((h - dom.clientHeight).toFixed(2)) / (h * 0.05));
          } else { //高大于宽
            d = Math.ceil(Number((h - dom.clientHeight).toFixed(2)) / (h * 0.05));
          }
          let z = 1 - Number(d * 0.05).toFixed(2);//缩放值
          setZoom(z);
          zoomRef.current = z;
          let top = parseInt((dom.clientHeight - (h * z)) / 2);
          let left = parseInt((dom.clientWidth - (w * z)) / 2);
          setImgTop(top);
          imgTopRef.current = top;
          setImgLeft(left);
          imgLeftRef.current = left;
          setBgImageUrl(data.picUrlOss);
        }

        let obj = {
          insPointId: data.insPointId,
          pipelineId: data.pipelineid,
          pipelinepointId: data.pipelinepointId
        };
        let arr = []; //ai识别
        for (let i = 0; i < data.abnormal_point.length; i++) {
          let point = data.abnormal_point[i];
          let idx = childIdsRef.current.indexOf(Number(point.airisktype));
          let a_obj = {
            id: point.id,//ai识别的带有id
            distance: point.distance,//距离
            x: parseInt(point.lx / multiple),//x轴
            y: parseInt(point.ly / multiple),//y轴
            width: parseInt(point.rx / multiple) - parseInt(point.lx / multiple),//宽度
            height: parseInt(point.ry / multiple) - parseInt(point.ly / multiple),//高度
            color: 'red',//边框颜色
            checked: false,//是否选中
            airisktype: Number(point.airisktype),//ai识别风险
            risktype: Number(point.risktype),//风险父级
            title: abnormalTypeRef.current[idx]?.name || '',//风险名称
            isAi: true,//是否为ai识别
            pointerEvents: 'none',//是否穿透
            threshold: Number(point.threshold),//阈值
          }
          let distance = 0;
          if (Number(point.distance) === 0) {
            distance = pointIsInLineRange({
              lx: a_obj.x, ly: a_obj.y, rx: a_obj.x + a_obj.width, ry: a_obj.y + a_obj.height
            });
          } else {
            distance = Number(point.distance);
          }
          a_obj.distance = distance;
          arr.push(a_obj);
        }
        setAbnormalInfo(obj);
        setAbnormalList(arr);
        setImgLoading(true);
        setLoading(false);
        loadingRef.current = false;
      }, 200)
    }
  }

  //图片查看页
  const onPatrolMap = () => {

    console.log('111', dataList, currIdx);
    const coordinate = {
      picUrl: bgImageUrl,
      longitude: dataList[currIdx].longitude, //经度
      latitude: dataList[currIdx].latitude, //纬度
    }

    let d = transformGCJ2WGS({ lat: coordinate.latitude, lon: coordinate.longitude });
    coordinate.latitude = Number(parseFloat(d.lat).toFixed(7));
    coordinate.longitude = Number(parseFloat(d.lon).toFixed(7));

    window.sessionStorage.setItem('viewpictureid=tb' + currIdx, JSON.stringify({
      pTitle: dataList[currIdx].pipe_name,
      title: dataList[currIdx].line_name,
      companyId: companyId,
      insIds: dataList[currIdx].insId,
      statid: dataList[currIdx].statid,
    }))
    window.sessionStorage.setItem('patrol_map?id=tb' + currIdx, JSON.stringify(coordinate))
    console.log('查看：（异常排查）', coordinate);
    window.open(basicPath + '/patrol_map?id=tb' + currIdx, '_blank')
  }

  //计算card高度
  const computeCardHeight = () => {
    let height = document.documentElement.clientHeight;
    let dom = document.getElementById('top');
    setCardHeight(height - dom.offsetTop - 24);
  }

  //异常标题元素
  const abnormalTitleDom = (info) => {
    let fontHeight = 22; //字体高度
    let fontSize = 14; //字体大小
    return (
      <div className='pos_abs fs14 pdRL6'
        style={{
          height: fontHeight,
          top: -1 * (fontHeight + rectangleBorder), left: -rectangleBorder, background: info.color, color: info.color === 'white' ? 'black' : 'white',
          overflow: 'hidden', whiteSpace: 'nowrap'
        }}
      >
        {Number(dataList[currIdx].routes_type) === 8 || Number(dataList[currIdx].routes_type) === 9 ? info.title : info.title + ' ' + info.distance + 'm' + (info.threshold !== '' ? '-' + info.threshold : '')}
      </div>
    )
  }

  //计算距离 pointList 航点列表  point 异常范围 {lx,ly,rx,ry}
  const pointIsInLineRange = (point) => {
    let pointList = waypointList.current;
    if (pointList.length === 0) {
      return 0;
    }
    let distance = 0;//距离
    let scale = pointList[0].scale;//比例
    let centerPoint = [parseInt(point.lx + ((Number(point.rx) - Number(point.lx)) / 2)), parseInt(point.ly + ((Number(point.ry) - Number(point.ly)) / 2))];//中心点
    for (let i = 0; i < pointList.length - 1; i++) {
      let val = pointList[i];
      let val2 = pointList[i + 1];
      let aSides = [val.x, val.y];//a边
      let bSides = [val2.x, val2.y];//a边
      if (distance === 0) {
        distance = getCoses(aSides, bSides, centerPoint) / scale;
        if (isNaN(distance)) {
          distance = 0;
        }
      }
      else {
        let dis = getCoses(aSides, bSides, centerPoint) / scale;
        if (isNaN(dis)) {
          continue;
        }
        if (dis < distance) {
          distance = dis;
        }
      }
    }
    return distance.toFixed(1);
  }

  //计算3角形3个角的角度
  // point1 端点1:A point2 端点2:B center 中心点:C
  const getCoses = (point1, point2, center) => {
    //求3边边长,利用勾股定理分别做3角型求出边长
    let AB = Math.sqrt(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2));
    let BC = Math.sqrt(Math.pow(point2[0] - center[0], 2) + Math.pow(point2[1] - center[1], 2));
    let CA = Math.sqrt(Math.pow(center[0] - point1[0], 2) + Math.pow(center[1] - point1[1], 2));

    //求3边反余弦弧度后转换成角度
    let cosABC = Math.acos((AB * AB + BC * BC - CA * CA) / (2 * AB * BC)) * (180 / Math.PI);
    let cosBCA = Math.acos((BC * BC + CA * CA - AB * AB) / (2 * BC * CA)) * (180 / Math.PI);
    let cosCAB = Math.acos((CA * CA + AB * AB - BC * BC) / (2 * CA * AB)) * (180 / Math.PI);

    if (cosABC >= 90) return BC;
    if (cosCAB >= 90) return CA;
    if (cosABC < 90 && cosCAB < 90) return CA * Math.sin(cosCAB * (Math.PI / 180));
  }

  //图片移动结束操作
  const imgMoveEndHandle = (e) => {
    e.preventDefault();
    isClickRef.current = false;
    firstXYRef.current = [];
    if (checkedKeysRef.current.length > 0 && isDraw) {//绘制 将数据添加到list中
      setIsDraw(false);
      //判断点击
      let date = new Date().getTime();
      let m = date - time.current.getTime(); //按下 到 抬起 的毫秒数 >200 移动  <=200 点击
      if (m > 200) { //移动
        let arr = [...abnormalList];
        let info = { ...drawInfo };
        const ltx = info.x;
        const lty = info.y;
        const rtx = info.x + info.width;
        const rty = info.y + info.height;
        const promise = new Promise((resolve, reject) => {
          let distance = pointIsInLineRange({ lx: ltx, ly: lty, rx: rtx, ry: rty });
          resolve(distance);
        });
        promise.then(
          result => {
            //计算距离
            info.pointerEvents = 'none';
            info.distance = result;
            arr.push(info);
            setAbnormalList(arr);
          }, // "Promise resolved" logged after 2 seconds
          error => console.log(error) // undefined, no error
        );
      } else { //点击
        setDrawInfo({
          id: '',
          distance: '',
          x: '',
          y: '',
          width: '',
          height: '',
          color: 'red',
          checked: false,
          airisktype: '',
          risktype: '',
          title: '',
          isAi: false,
          pointerEvents: 'none',
          threshold: '',
        });
      }
    }
  }

  //异常移动结束操作
  const abnormalMoveEndHandle = (e, idx) => {
    e.preventDefault();
    if (!abnormalIsClickRef.current) {
      return;
    }
    abnormalIsClickRef.current = false;
    firstXYRef.current = [];
    let arr = [...abnormalList];
    let checked = arr[idx].checked
    arr[idx].checked = !checked;
    arr[idx].color = !checked ? 'white' : 'red';
    arr[idx].pointerEvents = 'none';
    setAbnormalList(arr);

    // let date = new Date().getTime();
    // let m = date - time.current.getTime(); //按下 到 抬起 的毫秒数 >200 移动  <=200 点击
    // if (m > 200) { //移动
    //   let arr = [...abnormalList];
    //   const ltx = arr[idx].x;
    //   const lty = arr[idx].y;
    //   const rtx = arr[idx].x + arr[idx].width;
    //   const rty = arr[idx].y + arr[idx].height;
    //   const promise = new Promise((resolve, reject) => {
    //     let distance = pointIsInLineRange({ lx: ltx, ly: lty, rx: rtx, ry: rty });
    //     resolve(distance);
    //   });
    //   promise.then(
    //     result => {
    //       //计算距离
    //       arr[idx].pointerEvents = 'none';
    //       arr[idx].distance = result;
    //       setAbnormalList(arr);
    //     }, // "Promise resolved" logged after 2 seconds
    //     error => console.log(error) // undefined, no error
    //   );


    // } else { //点击
    //   let arr = [...abnormalList];
    //   let checked = arr[idx].checked
    //   arr[idx].checked = !checked;
    //   arr[idx].color = !checked ? 'white' : 'red';
    //   arr[idx].pointerEvents = 'none';
    //   setAbnormalList(arr);
    // }
  }

  //下一张
  const nextHandel = (num) => {
    let cur = currIdx;
    cur = cur + num;
    let arr = [...dataList];
    if (num === 0) {
      arr.splice(cur, 1);
      dataListRef.current = [...arr];
      if (arr.length === 0) {
        message.success('异常排查完成，即将返回');
        setTimeout(() => {
          navigate(-1);
        }, 1000);
        return;
      } else {
        if (cur === arr.length) { //说明处理的最后一条
          cur = cur - 1;
        }
        setDataList([...arr]);
      }
    }

    setCheckedKeys([]);
    checkedKeysRef.current = [];

    setLoadingText('加载中...');
    setLoading(true);
    loadingRef.current = true;
    setCurrIdx(cur);
    currIdxRef.current = cur;
    setTimeout(() => {
      setExist(!is_exist);
    }, 500)
  }

  // 监听键盘事件 通过键盘按键 调用 对应按钮的Click事件
  // -> : 下一张  
  // <- : 上一张
  // Delete : 删除选中的风险
  const onkeydown = (e) => {
    // eslint-disable-next-line
    switch (e.code) {
      case 'ArrowRight': //下一张
        if (currIdxRef.current <= dataList.length - 1 && !loadingRef.current) {
          nextBtnRef.current.click();
        }
        break;

      case 'Delete': //删除
        deleteBtnRef.current.click();
        break;

      case 'ArrowLeft': //上一张
        if (currIdxRef.current > 0 && !loadingRef.current) {
          lastBtnRef.current.click();
        }
        break;
    }

  }

  //提交 忽略AI识别区域坐标
  //提交 忽略的坐标
  //1 忽略  0 不忽略
  async function IgnoreAIAxisFun(info) {
    let params = {
      access_token: token,
      id: '',// id
      insId: abnormalInfo.insPointId,
      pipelineId: abnormalInfo.pipelineId,
      pipelinepointId: abnormalInfo.pipelinepointId,
      risktype: info.airisktype,//风险类型
      status: 1,//状态
      lx: info.x,//左上角 x坐标
      ly: info.y,//左上角 y坐标
      rx: parseInt(info.x + info.width),//左上角 x坐标
      ry: parseInt(info.y + info.height),//左上角 y坐标
      threshold: info.threshold,//阈值
    };
    const res = await IgnoreAIAxis(params);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }

  }

  //提交
  const submit = async () => {
    try {
      setLoadingText('上传中...');
      setLoading(true);
      let params = {
        access_token: token,
        companyId: companyId,
        statid: dataList[currIdx].statid,
        pointId: dataList[currIdx].pointId,
      }
      let arr = [];
      let type = [];
      let list = [];
      for (let i = 0; i < abnormalList.length; i++) {
        let val = abnormalList[i];
        let optionInfo = abnormalType[childIdsRef.current.indexOf(val.airisktype)];
        console.log('信息', optionInfo);
        let distance = Number(val.distance);//距离
        let obj = {
          insID: dataList[currIdx].insId,
          pointId: dataList[currIdx].pointId,
          lx: val.x,
          ly: val.y,
          rx: val.x + val.width,
          ry: val.y + val.height,
          risktype: val.risktype,
          airisktype: val.airisktype,
          distance: distance,
        };
        type.push(val.airisktype);
        arr.push(obj);
        list.push(val);
        // if (val.isAi) {
        //   type.push(val.risktype);
        //   arr.push(obj);
        //   list.push(val);
        //   continue;
        // }

        // if (Number(optionInfo.is_identify) === 1) { //异常 需要 处理
        //   if (Number(optionInfo.min_range) !== 0 || Number(optionInfo.max_range) !== 0) { //不是全图警报
        //     if (distance >= Number(optionInfo.min_range) && distance <= Number(optionInfo.max_range)) {
        //       type.push(val.risktype);
        //       arr.push(obj);
        //       list.push(val);
        //     }
        //   } else {
        //     type.push(val.risktype);
        //     arr.push(obj);
        //     list.push(val);
        //   }
        // }
      }
      params.abnormal_type = type.length > 0 ? Array.from(new Set(type)).join(',') : 1;
      params.list = arr;
      setAbnormalList(list);
      loadingRef.current = true;
      let res = await SubCheckImg(params);
      if (res.ret !== 200) {
        setLoading(false);
        loadingRef.current = false;
        message.warning(res.msg || '数据跑丢了~');
        return;
      }
      //有需要删除的风险时执行
      if (deleteIds.length > 0) {
        let res2 = await DelAIAxis({
          access_token: token,
          ids: deleteIds.join(','),// id
          status: 0,//状态
        });
        if (res2.ret !== 200) {
          message.warning(res.msg || '数据跑丢了~');
        }
      }
      setCheckedKeys([]);
      checkedKeysRef.current = [];

      nextHandel(0);
      message.success(res.msg);
      setLoading(false);
      loadingRef.current = false;
    } catch (error) {
      setLoading(true);
      message.warning(error.message);
    }

  }

  useEffect(() => {
    initialize();
    computeCardHeight();
    window.addEventListener('resize', computeCardHeight);//监听窗口改变
    window.addEventListener('keyup', onkeydown);//监听窗口改变
    return () => {
      window.removeEventListener('resize', computeCardHeight); // 销毁
      window.removeEventListener('keyup', onkeydown); // 销毁
    };
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isInitialize) {
      initialize();
    }
    // eslint-disable-next-line
  }, [is_exist])


  return (
    <div>
      <div className="flex_col" >
        <div className='flex_row align_center pdRL24' style={{ width: '100% ', height: '72px' }}>
          <span className='pointer' onClick={() => { navigate(-1) }}><ArrowLeftOutlined /> </span>
          <span className='fs20 fw500 marR12 marL18'>{'异常排查 - ' + dataList[currIdx]?.line_name + ' - ' + dataList[currIdx]?.pipe_name}</span>
          <span className='flex-auto'></span>
          <span>
            <img alt='hourglass' src={hourglass} style={{ width: '20px', height: '20px' }} />
            <Badge className="site-badge-count-109 fSize12" count={dataList.length ? dataList.length : 0}
              style={{
                backgroundColor: '#FF4D4F',
                color: '#FFFFFF',
                boxShadow: '0 0 0 1px #000',
              }}
            />
          </span>
        </div>
      </div>

      <div id='top'></div>
      <Spin spinning={loading} tip={loadingText}>
        <Card bodyStyle={{ background: 'black', padding: '0 24px' }}>
          <div className='flex_row' style={{ height: cardHeight }}>
            <div className='flex_auto marR10 hidden' id='mainDiv' style={{ background: '#141414' }}
              onWheel={(e) => {
                let czoom = zoomRef.current;
                let step = 0.05;//缩放步长
                let w = (imgWidth * step) / 2;
                let h = (imgHeight * step) / 2;
                let top = 0;
                let left = 0;

                if (e.deltaY > 0) { //下滚
                  if (czoom === 0.2) {
                    return;
                  }
                  czoom = Number((czoom - step).toFixed(2));
                  top = imgTop + h;
                  left = imgLeft + w;

                } else { //上滚
                  if (czoom === 3) {
                    return;
                  }
                  czoom = Number((czoom + step).toFixed(2));
                  top = imgTop - h;
                  left = imgLeft - w;
                }
                setZoom(czoom);
                zoomRef.current = czoom;

                setImgTop(top);
                imgTopRef.current = top;
                setImgLeft(left);
                imgLeftRef.current = left;
              }}
            >
              <div id='imgDiv' className='pos_rel'
                style={{ width: imgWidth * zoom, height: imgHeight * zoom, top: imgTop, left: imgLeft, display: imgLoading ? '' : 'none' }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  //计时
                  time.current = new Date();
                  if (checkedKeysRef.current.length > 0) {
                    setIsDraw(true);
                    firstXYRef.current = [e.nativeEvent.offsetX, e.nativeEvent.offsetY];
                    isClickRef.current = true;
                    let info = {
                      id: '',//ai识别的带有id
                      distance: '',//距离
                      x: parseInt(e.nativeEvent.offsetX / zoom),//x轴
                      y: parseInt(e.nativeEvent.offsetY / zoom),//y轴
                      width: 0,//宽度
                      height: 0,//高度
                      color: 'red',//边框颜色
                      checked: false,//是否选中
                      airisktype: checkedKeysRef.current[0],//ai识别风险
                      risktype: checkedInfoRef.current.parentKey,//风险父级
                      title: checkedInfoRef.current.title,//风险名称
                      isAi: false,//是否为ai识别
                      pointerEvents: 'none',//是否穿透
                      threshold: '',//阈值
                    }
                    setDrawInfo(info);
                  } else {
                    isClickRef.current = true;
                    firstXYRef.current = [e.pageX, e.pageY];
                  }
                }}
                onMouseMove={(e) => {
                  e.preventDefault();
                  if (isClickRef.current) {
                    if (checkedKeysRef.current.length > 0) { //画框
                      let info = { ...drawInfo };
                      let distancex = parseInt(Math.abs(firstXYRef.current[0] - e.nativeEvent.offsetX) / zoom);
                      let distancey = parseInt(Math.abs(firstXYRef.current[1] - e.nativeEvent.offsetY) / zoom);
                      info.width = distancex;
                      info.height = distancey;
                      setDrawInfo(info);

                    } else { //移动
                      let distancex = firstXYRef.current[0] - e.pageX;
                      let distancey = firstXYRef.current[1] - e.pageY;
                      let top = imgTopRef.current - distancey;
                      let left = imgLeftRef.current - distancex;
                      imgTopRef.current = top;
                      imgLeftRef.current = left;
                      setImgTop(top);
                      setImgLeft(left);
                      firstXYRef.current = [e.pageX, e.pageY];
                    }

                  }
                }}
                onMouseUp={imgMoveEndHandle}
                onMouseOver={imgMoveEndHandle}
              >
                <img src={bgImageUrl} alt="拍照" className='wid100 hei100'></img>
                {/* 所有的异常框选 */}
                <>
                  {
                    abnormalList.length > 0 && abnormalList.map((val, idx) => {
                      return (
                        <div key={idx} className='pos_abs'
                          style={{
                            top: parseInt(val.y * zoom), left: parseInt(val.x * zoom), width: parseInt(val.width * zoom), height: parseInt(val.height * zoom),
                            border: `${rectangleBorder}px solid ${val.color}`,
                            pointerEvents: isDraw ? 'none' : ''
                          }}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //计时
                            time.current = new Date();

                            firstXYRef.current = [e.pageX, e.pageY];
                            abnormalIsClickRef.current = true;
                            let arr = [...abnormalList];
                            arr[idx].pointerEvents = '';
                            setAbnormalList(arr);
                          }}
                          // onMouseMove={(e) => {
                          //   e.preventDefault();
                          //   if (abnormalIsClickRef.current) {
                          //     let list = [...abnormalList];
                          //     let distancex = parseInt((firstXYRef.current[0] - e.pageX) / zoom);
                          //     let distancey = parseInt((firstXYRef.current[1] - e.pageY) / zoom);
                          //     let top = list[idx].y - distancey;
                          //     let left = list[idx].x - distancex;
                          //     list[idx].y = top;
                          //     list[idx].x = left;
                          //     firstXYRef.current = [e.pageX, e.pageY];
                          //     setAbnormalList(list)
                          //   }
                          // }}
                          onMouseUp={(e) => { abnormalMoveEndHandle(e, idx) }}
                          onMouseOver={(e) => { abnormalMoveEndHandle(e, idx) }}
                        >
                          {/* 标签 14px */}
                          {abnormalTitleDom(val)}
                        </div>
                      )
                    })
                  }
                </>

                {/* 正在绘制的异常框选 */}
                <>
                  {
                    isDraw &&
                    <div className='pos_abs'
                      style={{
                        top: parseInt(drawInfo.y * zoom), left: parseInt(drawInfo.x * zoom), width: parseInt(drawInfo.width * zoom), height: parseInt(drawInfo.height * zoom),
                        border: `${rectangleBorder}px solid ${drawInfo.color}`,
                        pointerEvents: drawInfo.pointerEvents
                      }}
                    >
                    </div>
                  }
                </>
              </div>
            </div>

            <div className='w240 flex_shrink0' style={{ background: 'black' }}>
              <Card bodyStyle={{ padding: 0 }}>
                <div className='flex_col' style={{ maxHeight: cardHeight }}>
                  <div className='paddTB16 paddRL24' style={{ borderBottom: '1px solid #303030' }}>
                    <div className='fs14 fw500 coL1890FF curPointer'
                      onClick={() => { onPatrolMap() }}>
                      {dataList[currIdx]?.location}
                    </div>
                    <div className='fs14 fw400 opa45'>
                      {dataList[currIdx]?.point_order}
                    </div>
                    <div className='fs14 fw400 opa45'>
                      {dataList[currIdx]?.addtime}
                    </div>
                  </div>

                  <div className='pdTB10 pdRL16 flex_row justify_between' style={{ borderBottom: '1px solid #303030' }}>
                    <Button ref={lastBtnRef} disabled={currIdx === 0 ? true : false}
                      onClick={() => nextHandel(-1)}
                    >上一张</Button>

                    <Button ref={nextBtnRef} disabled={currIdx === dataList.length - 1 ? true : false}
                      onClick={() => nextHandel(1)}
                    >下一张</Button>
                  </div>

                  <div className='pdTB10 pdL16 flex_row flex_wrap' style={{ borderBottom: '1px solid #303030' }}>
                    <Button ref={deleteBtnRef} className='marR8' onClick={() => {
                      let list = [...abnormalList];
                      let arr = [];
                      let ids = [];
                      for (let i = 0; i < list.length; i++) {
                        let val = list[i];
                        if (!val.checked) {
                          arr.push(val);
                        } else {
                          if (val.id) {
                            ids.push(val.id);
                          }
                        }
                      }
                      setAbnormalList(arr);
                      setDeleteIds(ids);
                    }}>
                      删除
                    </Button>

                    <Button className='marR8' onClick={() => {
                      let list = [...abnormalList];
                      let arr = [];
                      let ids = [];
                      for (let i = 0; i < list.length; i++) {
                        let val = list[i];
                        if (!val.checked) {
                          arr.push(val);
                        } else {
                          IgnoreAIAxisFun(val);
                        }
                      }
                      setAbnormalList(arr);
                      setDeleteIds(ids);
                    }}>忽略</Button>

                    <Button type="primary" onClick={() => {
                      submit();
                    }}>确认</Button>

                  </div>

                  <div className='flex_auto pdRL16 marT12 marB16' style={{ overflow: 'auto' }}>
                    <Tree
                      checkable
                      showLine={{ showLeafIcon: <div></div> }}
                      expandedKeys={expandedKeys}
                      showIcon={true}
                      autoExpandParent={true}
                      defaultExpandAll='true'
                      defaultExpandParent={true}
                      onSelect={() => { }}
                      checkedKeys={checkedKeys}//默认选中复选框的节点
                      onCheck={(keys, info) => {
                        let key = info.checkedNodes.length > 0 ? [info.checkedNodes[info.checkedNodes.length - 1].key] : [];
                        let obj = info.checkedNodes.length > 0 ? info.checkedNodes[info.checkedNodes.length - 1] : '';
                        setCheckedKeys(key);
                        checkedKeysRef.current = key;
                        checkedInfoRef.current = obj;
                      }}
                      onExpand={(keys) => {
                        setExpandedKeys(keys);
                      }}
                      treeData={treeData}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div >
        </Card >
      </Spin >

    </div >
  )
}
export default AbnormalTroubleshootingSteps